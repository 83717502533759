import React, { useEffect, useState } from 'react';
import {
    OverlayContainer,
    CloseButton,
    MenuItemDetail,
    HeaderSection,
    ImageContainer,
    ExplanationText
} from './MenuDetails.styles';
import { GrClose } from 'react-icons/gr';
import { Tag } from './MenuItem.styles';
import { StartOrderButton } from "../Button"; // Tag 스타일 import

interface MenuDetailsProps {
    menuItem: any;
    onClose: () => void;
}

const MenuDetails: React.FC<MenuDetailsProps> = ({ menuItem, onClose }) => {
    const [isVisible, setIsVisible] = useState(false);
    const OnlineOrderURL = 'https://www.clover.com/online-ordering/tastea-deli-austin/'

    const handleOrderButtonClick = () => {
        window.location.href = OnlineOrderURL
    };

    function formatPrice(priceString: string) {
        if (priceString.length < 2) {
            return "$0.00";
        }

        const lastTwoDigits = priceString.slice(-2);
        const remainingDigits = priceString.slice(0, -2) || "0";

        // 최종 문자열 생성
        return `$${remainingDigits}.${lastTwoDigits}`;
    }

    useEffect(() => {
        // 컴포넌트가 마운트되면 애니메이션 시작
        setIsVisible(true);
    }, []);

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => {
            onClose(); // 애니메이션 시간이 끝난 후 실행
        }, 300); // 애니메이션 시간이 끝난 후 실행
    };

    const renderExplanation = () => {
        if (!menuItem.explanation) return null;

        const lines = menuItem.explanation.split('==');
        return lines.map((line: string, index: number) => (
            <span key={index}>{line.trim()}</span>
        ));
    };

    return (
        <OverlayContainer className={isVisible ? 'open' : ''}>
            <CloseButton onClick={handleClose}>
                <GrClose size={28} />
            </CloseButton>
            <HeaderSection>
                <h2>{menuItem.name}</h2>
            </HeaderSection>
            <MenuItemDetail>
                <ImageContainer>
                    {menuItem.tag && <Tag $tagType={menuItem.tag}>{menuItem.tag}</Tag>}
                    <img src={menuItem.imageUrl} alt={menuItem.name} />
                </ImageContainer>
                <ExplanationText>
                    {renderExplanation()}
                </ExplanationText>
            </MenuItemDetail>
            <StartOrderButton onClick={handleOrderButtonClick}>
                START ORDER
            </StartOrderButton>
        </OverlayContainer>
    );
};

export default MenuDetails;
