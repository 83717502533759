import styled from 'styled-components';

export const StartOrderButton = styled.button`
    position: fixed;
    bottom: 1rem; /* 화면 하단에서 1rem 위 */
    left: 50%;
    transform: translateX(-50%); /* 버튼을 중앙 정렬 */
    padding: 0.8rem 7rem;
    background-color: var(--hover-text-color); /* 버튼의 배경색 */
    color: white; /* 버튼의 글씨 색 */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    z-index: 1000; /* 다른 요소보다 위에 표시되도록 */

    &:hover {
        background-color: var(--hover-bg-color); /* 호버 시 배경색 변경 */
    }
`;
